<template>
  <div class="station-detail" ref="mapStation">
    <button class="station-detail__back-button" @click="stationDetailClose()">
      <ArrowLeftIcon /> {{ $t('backButton') }}
    </button>
    <div class="station-detail__wrapper">
      <button class="map-close-button" @click="stationDetailClose()"><CloseIcon /></button>
      <p
        v-if="selectedStation.facility === 'SOLAR' && !selectedStation.comingSoon"
        class="panel panel--bg station-detail__solar-heading"
      >
        <SolarWhite class="image" /> 100% Solar Powered
      </p>

      <div class="panel station-detail__meta">
        <div>
          <h2 class="headline4">{{ selectedStation.name }} <span class="sr-only"> station details</span></h2>
          <p class="station-address">
            {{ selectedStation.address }}, {{ selectedStation.city }}, {{ selectedStation.state }}
            {{ selectedStation.zipCode }}
          </p>
          <p v-if="selectedStation.description">{{ selectedStation.description }}</p>
        </div>
        <div>
          <button class="directions" @click="openDirections" aria-label="Get direction">
            <span class="sr-only">Directions</span>
            <IconDirections class="img" />
          </button>
        </div>
      </div>
      <p v-if="selectedStation.comingSoon">{{ $t('comingSoon') }}</p>
      <template v-if="!selectedStation.comingSoon">
        <div class="panel station-detail__chargers">
          <h3 class="map-panel-headline">{{ $t('chargersAtStation', { stationID: selectedStation.id }) }}</h3>
          <div class="panel panel--blue">
            <div class="arrow-link link">
              <router-link :to="{ name: `mobile-app-${$i18n.locale}` }" class=""
                >{{ $t('realTimeAvailability') }}
              </router-link>
              <span class="arrow" />
            </div>
            <p>{{ $t('realTimeInTheApp') }}.</p>
          </div>
          <ul>
            <li
              class="panel panel--bg"
              v-for="(charger, $index) in filteredStationTypes"
              :key="$index"
              :id="charger.id"
            >
              <strong>{{ charger.id.split('-').pop() }}</strong>
              <div class="connectors">
                <StationDetailConnector
                  v-for="(connector, $index) in charger.connectors"
                  :key="$index"
                  :id="connector.id"
                  :connector="connector"
                />
              </div>
            </li>
          </ul>
          <button
            class="view-all button-link link"
            style="font-size: 13px"
            @click="toggleStationConnectors"
            v-if="this.selectedStation.stationTypes.length > 4"
          >
            {{ viewStationConnectors ? 'View less' : 'View all' }}
          </button>
        </div>

        <div v-if="selectedStation.facility === 'SOLAR'" class="panel station-detail__solar-disclaimer">
          <SolarBlue class="image" />
          <p>
            <strong>The chargers at this station are entirely solar powered.</strong> When you arrive, check the power
            indicator to ensure adequate charge.
          </p>
        </div>

        <div class="panel station-detail">
          <router-link
            class="connector-type button-link link"
            :to="{ name: 'getting-started-' + getLang }"
            rel="noopener"
            target="_blank"
          >
            {{ $t('whatConnectorType') }}
          </router-link>
        </div>

        <div class="panel station-detail__pricing">
          <h3 class="map-panel-headline">{{ $t('pricing') }}</h3>

          <div class="panel--grey" v-if="!selectedStation.comingSoon">
            <img src="@/assets/images/LocateCharger/electric-bolt.webp" alt="" />
            <div>
              <div class="arrow-link link">
                <router-link :to="{ name: `mobile-app-${$i18n.locale}` }"
                  >{{ $t('downloadTheApp2') }}<arrow class="arrow"></arrow
                ></router-link>
              </div>
              <p>{{ $t('viewPricing') }}</p>
            </div>
          </div>

          <p>
            {{ $t('pricingMessage') }}
          </p>
          <router-link
            class="connector-type button-link link"
            :to="{ name: `pricing-${$i18n.locale}` }"
            rel="noopener"
            target="_blank"
          >
            {{ $t('pricingLearnMore') }}
          </router-link>
        </div>
        <div class="panel map-panel-headline">
          {{ $t('realTimeAvailability') }}.
          <div class="appStore">
            <a
              :href="`https://apps.apple.com/ca/app/electrify-canada/id1472391910?l=${$i18n.locale}`"
              rel="noopener"
              target="_blank"
            >
              <img
                loading="lazy"
                :src="require(`@/assets/images/Global/app-store-logo-${$i18n.locale}.png`)"
                :alt="$t('downloadInTheAppStore')"
              /> </a
            ><br />
            <a
              :href="`https://play.google.com/store/apps/details?id=com.ec.evowner&hl=${$i18n.locale}_CA`"
              rel="noopener"
              target="_blank"
            >
              <img
                loading="lazy"
                :src="require(`@/assets/images/Global/google-play-badge-${$i18n.locale}.png`)"
                :alt="$t('getItOnGooglePlay')"
              />
            </a>
          </div>
        </div>
      </template>
    </div>
    <template v-if="selectedStation.comingSoon">
      <div class="station-detail__upsell">
        <router-link :to="{ name: getLang === 'en' ? 'mobile-app-en' : 'mobile-app-fr' }" class="botton-link"
          >{{ $t('downloadTheApp') }}<span class="arrow" />
        </router-link>
        <p>{{ $t('createAccount') }}</p>
        <img v-if="getLang === 'en'" width="100%" src="@/assets/images/Map/upsell-en.png" alt="" />
        <img v-else width="100%" src="@/assets/images/Map/upsell-fr.png" alt="" />
      </div>
    </template>
  </div>
</template>

<script>
import ArrowLeftIcon from '@/assets/images/Map/arrow-left.svg';
import CloseIcon from '@/assets/images/Map/close-icon.svg';
import IconDirections from '@/assets/images/Map/Icon_Directions.svg';
import SolarBlue from '@/assets/images/Map/SolarIcon_Blue.svg';
import SolarWhite from '@/assets/images/Map/SolarIcon_White.svg';
import StationDetailConnector from '@/components/LocateCharger/StationDetailConnector.vue';
import lang from '@/lang/LocateCharger';
import globalLang from '@/lang';

export default {
  name: 'StationDetail',
  props: ['selectedStation', 'isSelectedStationDetailsLoaded'],
  components: {
    ArrowLeftIcon,
    CloseIcon,
    IconDirections,
    SolarBlue,
    SolarWhite,
    StationDetailConnector,
  },
  i18n: {
    messages: globalLang,
    sharedMessages: lang,
  },
  data() {
    return {
      passPlusSessionFee: 0,
      passSessionFee: 0,
      viewStationConnectors: false,
    };
  },
  methods: {
    openDirections() {
      if (this.selectedStation && this.selectedStation.position) {
        const lat = encodeURIComponent(this.selectedStation.position.lat);
        const lng = encodeURIComponent(this.selectedStation.position.lng);
        window.open(`https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`, '_blank');
      }
    },
    stationDetailClose() {
      this.$emit('stationDetailClose');
    },
    toggleStationConnectors() {
      this.viewStationConnectors = !this.viewStationConnectors;
    },
  },
  computed: {
    filteredStationTypes() {
      if (this.viewStationConnectors) {
        return this.selectedStation.stationTypes;
      }
      return this.selectedStation?.stationTypes?.slice(0, 4);
    },
    getLang() {
      return this.$store.state.lang;
    },
  },
};
</script>

<style lang="scss" scoped>
.panel {
  display: flex;
  flex-direction: column;
  margin: 0 0 18px 0;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &--bg {
    background-color: #eef0f4;
    border-radius: $global-border-radius;
    padding: 8px 16px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &--blue {
    background-color: #d7dcf3;
    border-radius: $global-border-radius;
    padding: 8px 16px;
    margin-bottom: 18px;
    .link {
      text-transform: uppercase;
      text-decoration: none;
    }
    .arrow-link {
      display: flex;
      align-items: center;
      &:hover {
        color: $c-focus;
        .arrow {
          background: $c-focus;
        }
      }
    }
  }
  .appStore {
    img {
      margin-top: 0;
      height: 50px;
      &:last-child {
        margin-top: 15px;
      }
    }
  }
  .panel--grey {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: $global-border-radius;
    padding: 8px 16px;
    margin-bottom: 18px;
    padding-top: 20px;
    img {
      width: 30px;
      height: auto;
      margin-right: 20px;
    }
    p {
      font-weight: $fw-regular;
      margin: 14px 0;
    }
    .link {
      text-transform: uppercase;
      text-decoration: none;
    }
    .arrow-link {
      display: flex;
      align-items: center;
      &:hover {
        color: $c-focus;
      }
    }
  }
}

.station-detail {
  position: relative;
  width: 100%;

  &__back-button {
    display: none;

    @media (min-width: 750px) {
      align-items: center;
      appearance: unset;
      background: transparent;
      border: 0;
      color: $c-gray-dark;
      display: flex;
      font-size: 12px;
      height: fit-content;
      left: 18px;
      margin-top: 0;
      position: absolute;
      text-transform: uppercase;
      top: 18px;
      z-index: 90;

      svg {
        background: #eaedf2;
        border-radius: 50%;
        height: 24px;
        margin-right: 12px;
        padding: 4px;
        width: 24px;
      }
    }
  }
  &__chargers {
    overflow: auto;

    strong {
      font-size: 24px;
      font-weight: 600;
      line-height: 1;
    }

    .view-all {
      appearance: unset;
      background: transparent;
      border: 0;
      margin: 12px auto 5px auto;
      padding: 5px;
      text-align: center;
    }
  }

  &__pricing {
    .pricing-table {
      border-collapse: separate;
      border-spacing: 10px 6px;
      font-size: 1.125rem;

      td {
        vertical-align: top;
      }

      th {
        color: $c-gray-dark;
        font-size: 12px;
        font-weight: $fw-medium;
      }

      th[scope='col'] {
        border-bottom: 1px solid #c9cfdc;
      }

      th[scope='row'] {
        text-align: left;
        max-width: 135px;

        h4 {
          color: #0e193f;
          font-size: 1.125rem;
          font-weight: $fw-medium;
          margin: 0;
        }
      }
    }
  }

  &__meta {
    flex-direction: row;
    justify-content: space-between;
  }

  &__solar-disclaimer {
    position: relative;

    .image {
      position: absolute;
      height: 20px;
      width: 20px;
    }
    p {
      font-size: 14px;
      line-height: 16px;
      font-weight: $fw-medium;
      margin: 0 0 0 30px;
    }
  }

  &__solar-heading {
    background-color: $c-border-blue;
    color: $c-primary-background;
    flex-direction: row;
    font-weight: $fw-medium;

    .image {
      height: 24px;
      margin: 0 8px 0 0;
      width: 24px;
    }
  }

  &__wrapper {
    background-color: $c-primary-background;
    margin-top: calc(40vh);
    padding: 20px;
    position: relative;

    @media (min-width: 750px) {
      height: fit-content;
      margin-top: 0;
      padding: 50px;
      padding-top: 70px;
      padding-bottom: 20px;
    }

    h2 {
      font-weight: $fw-medium;
    }

    p {
      font-size: 14px;
    }

    .directions {
      appearance: unset;
      background: transparent;
      border: 0;
      margin-left: 10px;

      .img {
        height: 40px;
        width: 40px;
      }
    }
  }
  &__upsell {
    background: $c-secondary-background;
    padding: 40px 28px;
    h3 {
      font-size: 16px;
      text-transform: uppercase;
    }
    p {
      margin-bottom: 0;
    }
    @media (min-width: 750px) {
      padding: 20px 60px;
    }
    img {
      margin-top: 20px;
    }
  }

  .connector-type.link,
  .view-all.link {
    text-transform: inherit;
    font-size: 12px;
    text-decoration: none;
  }
}
</style>
