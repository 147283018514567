<template>
  <div class="search">
    <div
      class="search__form"
      :class="[
        {
          'search__form--with-results':
            isFilterPanelExpanded ||
            isStationListExpanded ||
            (searchResults.length >= 0 && isSearchActive && searchInput),
        },
      ]"
      ref="search"
      v-if="
        (isMobile && markersRetrieved && !isFilterPanelExpanded && !isStationListExpanded) ||
        (isMobile && $route.query.search !== '' && !isFilterPanelExpanded && !isStationListExpanded) ||
        !isMobile
      "
    >
      <form class="search__wrapper" ref="searchWrapper" role="search" @submit.prevent @keydown.enter="focusOnResults">
        <div class="search__input">
          <input
            type="text"
            v-model="searchInput"
            @focus="onSearchInputFocus"
            :placeholder="$t('search')"
            ref="searchInput"
          />
          <SearchIcon class="search__input__icon" />
          <button
            class="search__input__delete"
            v-if="searchDelete"
            ref="closeButton"
            :aria-label="$t('clearSearch')"
            @click="clearSearch"
          >
            <Delete />
          </button>
        </div>
      </form>
      <button
        ref="filter"
        class="search__filter"
        :aria-label="$t('filters')"
        :aria-expanded="isFilterPanelExpanded ? 'true' : 'false'"
        @click="toggleFilterMenu"
      >
        <template v-if="!isFilterPanelExpanded">
          <span v-if="!isMobile">{{ $t('filters') }}</span
          ><FilterIcon alt="" class="search__filter__img" />
        </template>
        <template v-else>
          <CloseIcon :alt="$t('closeFilter')" class="search__filter__img" />
        </template>
      </button>
    </div>
    <!--Search Results -->
    <div v-show="isSearchActive && searchInput && !isStationListExpanded" class="search__results" ref="searchResults">
      <h2 class="sr-only">
        {{ $t('searchResults') }}{{ searchInput !== '' ? ' ' + $t('for') + ' "' + searchInput + '"' : '' }}
      </h2>
      <p class="search__results__no-results" v-if="searchResults.length == 0">
        {{ $t('searchResultsNotFound', { value: searchInput }) }}
      </p>
      <ul class="search__results__list" v-if="searchResults.length > 0">
        <li v-for="(loc, index) in searchResults" :key="index" class="search__results__list-item">
          <button class="search__results__button" @click="goToLocation(loc)">
            <h3 class="search__results__item-name">{{ loc.main }}</h3>
            <div class="search__results__item-loc">{{ loc.secondary }}</div>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import lang from '@/lang/LocateCharger';

import CloseIcon from '@/assets/images/Map/close-icon.svg';
import Delete from '@/assets/images/Map/delete.svg';
import FilterIcon from '@/assets/images/Map/filter.svg';
import SearchIcon from '@/assets/images/Map/mag.svg';

export default {
  name: 'Search',
  props: ['isStationDetailExpanded', 'selectedStation', 'markersRetrieved'],
  components: {
    CloseIcon,
    Delete,
    FilterIcon,
    SearchIcon,
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  data() {
    return {
      searchResults: [],
      searchDelete: false,
      page: 0,
      showFilter: false,
    };
  },
  mounted() {
    this.$root.$on('focusSearchInput', () => {
      const searchRef = this.$refs.searchInput;
      searchRef.focus();
    });

    // clear input after station results is reset
    this.$root.$on('stationReset', () => {
      this.searchInput = '';
    });

    // If there is a search query then the search should be active on mount
    if (this.$route.query.search) {
      const searchRef = this.$refs.searchInput;
      searchRef.focus();
    }
  },
  methods: {
    focusOnResults(event) {
      event.preventDefault();
      if (event.target.classList.contains('search__input__delete')) {
        this.clearSearch();
        return;
      }
      if (this.searchResults.length > 0) {
        const firstResult = this.$refs.searchResults?.querySelector('.search__results__button');
        firstResult.focus();
      }
    },
    clearSearch() {
      this.$store.commit('map/setIsSearchActive', true);
      this.searchInput = '';
      const searchRef = this.$refs.searchInput;
      searchRef.focus();
      this.$store.commit('map/setIsFilterPanelExpanded', false);
      this.$store.commit('map/setIsStationDetailExpanded', false);
      this.$store.commit('map/setIsStationListExpanded', false);
    },

    // when clicking on a search result from the search box,
    // this takes you to the location on the map
    goToLocation(location) {
      this.$store.commit('map/setIsSearchActive', false);

      if (location.isStationMarker) {
        this.$root.$emit('stationDetailOpen', location.marker);
        this.searchResults = [];
        this.$root.$emit('expandStationList');
        return;
      }

      this.$store.dispatch('map/goToLocation', location.id).then(() => {
        this.$nextTick(() => {
          this.searchResults = [];
          this.$root.$emit('expandStationList');
        });
      });
    },

    onSearchInputFocus() {
      this.$store.commit('map/setIsSearchActive', true);
    },

    // creating logic to detect to return back to the
    // main search menu based on a few conditions
    returnToSearch() {
      this.$refs.chargerMenu.style.visibility = 'visible';
      this.$store.dispatch('map/setZoom', 10);
      if (!this.isFilterPanelExpanded) {
        this.showFilterMenu();
      }
      if (this.isFilterPanelExpanded) {
        this.$refs.chargerMenu.style.visibility = 'hidden';
        this.isFilterPanelExpanded = !this.isFilterPanelExpanded;
        this.$refs.filterMenu.classList.toggle('filter-closed');
        this.$refs.filterMenu.classList.toggle('filter-open');
        this.hideFilterMenu();
      } else if (this.isStationDetailExpanded) {
        if (this.$refs.mapStation.classList.contains('station--open')) {
          this.$emit('stationDetailClose');
          this.$refs.mapStation.classList.replace('station--open', 'station--closed');
        }
      }
      if (this.$refs.filterMenu.classList.contains('filter-close')) {
        this.$refs.chargerMenu.style.visibility = 'hidden';
      } else {
        this.$refs.chargerMenu.style.visibility = 'visible';
      }
    },

    toggleFilterMenu() {
      if (this.isFilterPanelExpanded) {
        this.$store.commit('map/setRestorePanelStatus');
        this.$store.commit('map/setIsFilterPanelExpanded', false);
      } else {
        this.$store.commit('map/setPanelStatusSnapshot');
        this.$store.commit('map/setIsFilterPanelExpanded', true);
        this.$store.commit('map/setIsSearchActive', false);
        this.$store.commit('map/setIsStationDetailExpanded', false);
        this.$store.commit('map/setIsStationListExpanded', false);
      }
    },

    useFocusTrap() {
      const focusableElementsString =
        'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable], ul, li, button, input[checkbox]';

      let focusableElements = this.$refs.search.querySelectorAll(focusableElementsString);
      const focusableElementsMenu = this.$refs.filterMenu.querySelectorAll(focusableElementsString);

      if (this.showFilter || this.$refs.filterMenu.classList.contains('filter-open')) {
        focusableElements = [...focusableElements, ...focusableElementsMenu];
      }

      const firstFocusable = focusableElements[0];

      const lastFocusable = focusableElements[focusableElements.length - 1];

      window.addEventListener('keydown', (e) => {
        if (e.key === 'Tab') {
          if (e.shiftKey) {
            if (document.activeElement === firstFocusable) {
              e.preventDefault();
              lastFocusable.focus();
            }
          } else if (document.activeElement === lastFocusable) {
            e.preventDefault();
            firstFocusable.focus();
          }
        }
      });
    },
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(750);
    },
    isFilterPanelExpanded() {
      return this.$store.state.map.isFilterPanelExpanded;
    },
    isSearchActive() {
      return this.$store.state.map.isSearchActive;
    },
    isStationListExpanded() {
      return this.$store.state.map.isStationListExpanded;
    },
    mapZoom() {
      return this.$store.state.map.mapZoom;
    },
    googleMapInstance() {
      return this.$store.state.map.googleMapInstance;
    },
    googleInstance() {
      return this.$store.state.map.googleInstance;
    },
    searchInput: {
      get() {
        return this.$store.state.map.searchInput;
      },
      set(newValue) {
        this.$store.commit('map/setSearchInput', newValue);
      },
    },
    getLang() {
      return this.$store.state.lang;
    },
  },
  watch: {
    selectedStation() {
      if (this.selectedStation !== null) {
        // this.$refs.search.classList.remove('search--active');
      }
    },
    // auto complete API Search Box
    async searchInput() {
      if (this.searchInput !== '' && this.searchInput) {
        this.searchDelete = true;
        if (this.$refs.search) {
          this.$refs.search.classList.add('search--active');
        }
        if (this.isSearchActive === true) {
          this.$store.commit('map/setIsStationListExpanded', false);
          this.searchResults = await this.$store.getters['map/getPlacePredictions'](this.searchInput);

          // If this looks like a station id, check if it is one and add it to the top of the results
          if (
            /^[\d]{6,7}$/.test(this.searchInput) &&
            this.$store.getters['locations/getLocationMarkerById'](this.searchInput)
          ) {
            const marker = this.$store.getters['locations/getLocationMarkerById'](this.searchInput);
            const stationTranslation = this.getLang === 'en' ? lang.en.station : lang.fr.station;
            const customLoc = {
              id: this.searchInput,
              main: `${stationTranslation} #${this.searchInput}, ${marker.name}`,
              secondary: `${marker.city}, ${marker.state}, Canada`,
              isStationMarker: true,
              marker,
            };
            this.searchResults = [customLoc, ...this.searchResults];
          }
        }
      } else {
        this.searchDelete = false;
        this.searchResults = [];
        if (this.$refs.search) {
          this.$refs.search.classList.remove('search--active');
        }
      }
    },
    searchResults(oldValue, newValue) {
      if (newValue.length > 0) {
        // this.useFocusTrap();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  margin: 0 5%;
  position: absolute;
  top: 70px;
  width: 90%;
  z-index: 100;

  @media (min-width: 750px) {
    left: 16px;
    margin: 0;
    top: 16px;
    width: 475px;
  }

  &__form {
    align-items: center;
    background-color: $c-primary-background;
    border-radius: $global-border-radius;
    box-shadow: 0 5px 17px 0 rgba(20, 25, 43, 0.3);
    box-sizing: border-box;
    display: flex;
    height: 50px;
    margin: 0 auto;
    transition: box-shadow 0.5s ease-out;

    @media (min-width: 750px) {
      border-radius: $global-border-radius;
      height: 67px;
      width: 100%;
    }

    &--with-results {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: none !important;
      transition: 750ms ease box-shadow;
    }
  }

  &__results {
    background-color: $c-primary-background;
    border-bottom-left-radius: $global-border-radius;
    border-bottom-right-radius: $global-border-radius;
    box-sizing: border-box;
    height: 100%;
    margin: 0 auto;
    width: 100%;
    z-index: 100;

    &__header {
      background-color: $c-border;

      h3 {
        color: $c-gray-dark;
        font-size: 16px;
        font-weight: $fw-light;
        margin: 0;
        padding: 0 16px;
      }
    }

    &__no-results {
      margin-top: 0;
      padding: 45px 16px;
      text-align: center;
    }

    &__list {
      list-style: none;
      margin: 0;
      max-height: 350px;
      overflow: auto;
      padding: 4px;
    }

    &__list-item {
      border-top: 1px solid $c-border;
      margin: 0;
      padding: 4px;
    }

    &__item-name {
      font-size: 16px;
      font-weight: $fw-medium;
      margin: 0;
    }

    &__item-loc {
      color: $c-gray-dark;
      font-size: 11px;
    }

    &__button {
      background-color: $c-primary-background;
      border: none;
      color: $c-primary-light;
      display: block;
      padding: 12px 8px;
      text-align: inherit;
      width: 100%;
    }
  }

  // wrapper for search bar
  &__wrapper {
    box-sizing: border-box;
    flex: 1 0 auto;
    position: relative;

    @media (min-width: 750px) {
      margin-left: 12px;
    }

    input::placeholder {
      color: $c-primary;
      font-weight: $fw-light;
    }
  }

  &__input {
    &__icon {
      position: absolute;
      top: 14px;
      left: 10px;
      height: 24px;
      width: 24px;
    }

    &__delete {
      border: none;
      background: transparent;
      cursor: pointer;
      pointer-events: all;
      position: absolute;
      right: 16px;
      top: 10px;
      width: 30px;
      height: 30px;
      @media (min-width: 750px) {
        right: 8px;
      }

      g {
        path {
          transition: 500ms ease all;
        }
      }
      &:hover {
        g {
          path:nth-child(2) {
            fill: $c-primary;
          }
        }
      }
    }

    input {
      background: $c-primary-background;
      border: none;
      border-radius: $global-border-radius;
      box-sizing: border-box;
      color: $c-primary;
      font-size: 16px;
      font-weight: $fw-regular;
      height: 50px;
      margin-bottom: 0;
      padding: 0 0 0 44px;
      position: relative;
      text-transform: uppercase;
      width: 100%;
      max-width: 100%;

      @media (min-width: 750px) {
        padding: 0 0 0 48px;
        min-width: 344px;
      }

      &::placeholder {
        font-weight: $fw-light;
        font-size: 12px;
      }
    }
  }

  // Filter Icon
  &__filter {
    align-items: center;
    background-color: #eef0f4;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    font-size: 18px;
    font-weight: $fw-medium;
    height: 100%;
    width: 50px;
    justify-content: center;
    margin-left: 5px;

    @media (min-width: 750px) {
      width: 106px;
      height: 50px;
      margin-right: 8px;
      transition: 750ms ease box-shadow;
    }

    @media (min-width: 750px) {
      & span {
        margin-right: 10px;
      }
    }

    &__img {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
